import { SportTypes } from '../../../constants/sport.types';
import REACT_APP_URLS from '../../../global-helpers/global-url.helpers';

const BASKETBALL_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_BASKETBALL_API_URL;
const TENNIS_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_TENNIS_API_URL;
const MULTI_SPORT_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_MULTISPORT_API_URL;

export const getSportApiUrl = (sport: string) => {
	switch (sport) {
		case SportTypes.BASKETBALL:
			return BASKETBALL_SEARCH_API_URL;
		case SportTypes.ICE_HOCKEY:
			return MULTI_SPORT_SEARCH_API_URL;
		default:
			return TENNIS_SEARCH_API_URL;
	}
};

export const getEntityType = (sport: string) => {
	switch (sport) {
		case SportTypes.BASKETBALL:
			return 'games';
		case SportTypes.ICE_HOCKEY:
			return 'multi-sport/events';
		default:
			return 'matches';
	}
};
