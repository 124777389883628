import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import CompetitionModel from '../../../../../models/v2/competition/entity/competition.model';
import { multiSportCompetitionToOption, multiSportCompetitionsToOptions } from './helpers/competition-select.helper';
import { customOption } from '../shared/custom-select-option';
import { SportTypes } from '../../../../../constants/sport.types';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import ErrorHandler from '../../v2/partials/error-handler/error-handler';
import { useTranslation } from 'react-i18next';
import MultisportHttpService from '../../../../../services/rest/multisport-http-service';

type Properties = {
	onCompetitionSelect: (competition: CompetitionModel) => void;
	selectedCompetition: CompetitionModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired: boolean;
	isBlocky: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
};

type CompetitionResponse = {
	code: string;
	competitions: Competition[];
	description: string;
	name: string;
};

type Competition = {
	competition: CompetitionModel;
	order: number;
};

const IceHockeyCompetitionSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [competitionOptions, setCompetitionsOptions] = useState<CompetitionModel[]>([]);
	const [t] = useTranslation();

	useEffect(() => {
		getCompetitions(props.sport, props.language);
	}, []);

	const updateCompetitionsOptionsState = (competitions: CompetitionModel[]) => {
		const { onCompetitionSelect, selectedCompetition, isBlocky } = props;
		if (
			!selectedCompetition ||
			(selectedCompetition && (selectedCompetition.id === undefined || selectedCompetition.id.length < 1) && isBlocky)
		) {
			onCompetitionSelect(competitions[0]);
		}
		setCompetitionsOptions(competitions);
	};

	const getCompetitions = (sport: string, lang: string) => {
		MultisportHttpService.getCompetitionListing(sport, lang)
			.then((response: any) => {
				const competitions: Competition[] = response.data.competitions;
				const iceHockeyCompetitions = competitions.filter(({ competition }: Competition) => competition.sport === SportTypes.ICE_HOCKEY);
				const formattedCompetitions = iceHockeyCompetitions
					.map(({ competition }: Competition) => competition)
					.filter((comp, index, current) => index === current.findIndex(({ id }) => id === comp.id));

				return updateCompetitionsOptionsState(formattedCompetitions);
			})
			.catch((e: any) => e);
	};

	return (
		<div data-qa={props.id}>
			<Label htmlFor={props.id}>{t('competition')}</Label>
			{props.isRequired && <span className='text-danger'>*</span>}
			<Select
				id={props.id}
				menuPortalTarget={document.body}
				menuPosition='absolute'
				className='mb-2 w-100'
				placeholder={t('select')}
				formatOptionLabel={customOption}
				options={multiSportCompetitionsToOptions(competitionOptions)}
				value={props.selectedCompetition && props.selectedCompetition.id ? multiSportCompetitionToOption(props.selectedCompetition) : []}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				isClearable={props.isClearable}
				onChange={(selection: SelectMenuOptionType) => {
					if (selection) {
						props.onCompetitionSelect(selection.data);
					} else {
						props.onCompetitionSelect({} as CompetitionModel);
					}
				}}
			/>
			{
				<div className='mt-2'>
					<ErrorHandler
						isValueSelected={!props.isValid && !props.selectedCompetition.id}
						arePropsValid={props.isValid}
						t={t}
						errorMessage='field_is_required'
					/>
				</div>
			}
		</div>
	);
};

export default IceHockeyCompetitionSelectComponent as React.ComponentType<Properties>;
