import { TypedJSON } from 'typedjson';
import IceHockeyStageModel from '../../../../../../models/v2/Stage/Entity/ice-hockey-stage.mode';

export const responseToStageModel = (response: any) => {
	const serializer = new TypedJSON(IceHockeyStageModel);
	const remmapedStage = serializer.parse(response) as IceHockeyStageModel;

	return remmapedStage;
};

export const stagesToOptions = (stages: IceHockeyStageModel[]) => {
	if (stages && stages.length > 0) {
		return stages.map((stage) => {
			return stageToOption(stage);
		});
	}

	return [];
};

export const stageToOption = (stage: IceHockeyStageModel) => {
	if (stage) {
		return {
			value: stage.id,
			label: stage.name,
			data: stage,
			type: 'stage',
		};
	} else {
		return {};
	}
};
