import React from 'react';
import { compose } from 'redux';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { ViewTypes } from '../../../../constants/general.constants';
import { BlockyV2Properties } from '../../../utils/helper';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import GameWidgetModel from '../../../../blocks/widgets/basketball-single-event/models/game-widget.model';
import OddsWidgetModel from '../../../../blocks/widgets/odds/models/odds-widget.model';
import { IceHockeySingleEventViewComponent } from './ice-hockey-single-event-view.component';
import IceHockeySingleEventWidgetEdit from './ice-hockey-single-event-edit.component';
import { useTranslation } from 'react-i18next';

const IceHockeySingleEventWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, block, contentData, contentLanguage } = props;
	const [t] = useTranslation();

	if (view === ViewTypes.edit) {
		return <IceHockeySingleEventWidgetEdit block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.ICE_HOCKEY} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<IceHockeySingleEventViewComponent
				gamePreview={
					props.block.data.preview && props.block.data.preview.game ? props.block.data.preview.game : GameWidgetModel.builder().build()
				}
				oddsPreview={
					props.block.data.preview && props.block.data.preview.odds ? props.block.data.preview.odds : OddsWidgetModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(IceHockeySingleEventWidget);
