import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import IceHockeyStageModel from '../../Stage/Entity/ice-hockey-stage.mode';

@jsonObject()
export class IceHockeySeasonModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonArrayMember(IceHockeyStageModel)
	public stages: IceHockeyStageModel[] = [];
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
	@jsonMember(String)
	public slug: string = '';
}
