import React from 'react';
import { Col, Row } from 'reactstrap';
import { generateRefreshTimeOptionViewText } from '../../../../v2/partials/refresh-time-input/helper';
import { generateHeaderDefaultOptionViewText } from '../../../../v2/partials/header-default-option-input/helper';
import { generateDisplayHeaderOptionViewText } from '../../../../v2/partials/header-display-input/helper';
import IceHockeyStandingsModel from '../models/ice-hockey-standings.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	preview: IceHockeyStandingsModel;
};

export const IceHockeyStandingsViewComponent: React.FunctionComponent<Properties> = ({ preview }) => {
	const [t] = useTranslation()

	if (preview && preview.competition && preview.competition.id) {
		return (
			<>
				<Row className='px-4' data-qa='ice-hockey-standings-view-component'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								<div>
									<strong>{t('competition')}: </strong>
									{preview.competition.name} {preview.season && preview.season.name && `- ${preview.season.name}`}
									{`${preview.stage && preview.stage.name ? `(${preview.stage.name})` : ''}`}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				{generateDisplayHeaderOptionViewText(preview.displayHeader)}
				{generateHeaderDefaultOptionViewText(preview.headerDefaultOption ? preview.headerDefaultOption : '')}
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
