import IceHockeyStageModel from '../../../../../../../models/v2/Stage/Entity/ice-hockey-stage.mode';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import { IceHockeySeasonModel } from '../../../../../../../models/v2/ice-hockey-season/entity/ice-hockey-season.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import { IceHockeyStandingsJson } from './ice-hockey-standings.json';
import IceHockeyStandingsModel from './ice-hockey-standings.model';

export default class IceHockeyStandingsBuilder {
	readonly json: IceHockeyStandingsJson;

	constructor(standings?: IceHockeyStandingsModel | IceHockeyStandingsJson) {
		if (standings && standings instanceof IceHockeyStandingsModel) {
			this.json = standings.toJson();
		} else if (standings) {
			this.json = standings;
		} else {
			this.json = {} as IceHockeyStandingsJson;
		}
	}

	withTournament(competition: CompetitionModel): IceHockeyStandingsBuilder {
		this.json.competition = competition;

		return this;
	}

	withSeason(season: IceHockeySeasonModel): IceHockeyStandingsBuilder {
		this.json.season = season;

		return this;
	}

	withStage(stage: IceHockeyStageModel): IceHockeyStandingsBuilder {
		this.json.stage = stage;

		return this;
	}

	withHeaderDefaultOption(headerDefaultOption: string | null): IceHockeyStandingsBuilder {
		this.json.headerDefaultOption = headerDefaultOption;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): IceHockeyStandingsBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	withDisplayHeader(displayHeader: boolean): IceHockeyStandingsBuilder {
		this.json.displayHeader = displayHeader;

		return this;
	}

	build(): IceHockeyStandingsModel {
		return IceHockeyStandingsModel.fromJson(this.json);
	}
}
