import React, { ChangeEvent } from 'react';
import { Col, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import BlockModel from '../../../../models/block.model';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../../../v2/widgets-v2/helpers/component-attributes.constants';
import { createBlock } from '../../../../v2/partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../../v2/partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../../v2/partials/refresh-time-input/refresh-time-wrapper';
import HeaderDefaultOptionWrapper from '../../../../v2/partials/header-default-option-input/header-default-option-wrapper';
import {
	BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTIONS,
	onSelectionChangeFromMainComponent,
} from '../../../../v2/partials/header-default-option-input/helper';
import HeaderDisplayInput from '../../../../v2/partials/header-display-input/header-display-input.component';
import IceHockeySeasonSelectComponent from '../../../../partials/season-select/ice-hockey-season-select.component';
import IceHockeyCompetitionSelectComponent from '../../../../partials/competition-select/ice-hockey-competition-select.component';
import IceHockeyStandingsModel from '../models/ice-hockey-standings.model';
import { IceHockeySeasonModel } from '../../../../../../../models/v2/ice-hockey-season/entity/ice-hockey-season.model';
import IceHockeyStageModel from '../../../../../../../models/v2/Stage/Entity/ice-hockey-stage.mode';
import IceHockeyStageSelectComponent from '../../../../partials/stage-select/ice-hockey-stage-select.component';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: IceHockeyStandingsModel;
	isValid: boolean;
};

export default class IceHockeyStandingsEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetIceHockeyStandings,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = !!validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		const standingsModel = IceHockeyStandingsModel.builder(this.state.widgetModel).build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: standingsModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: IceHockeyStandingsModel) => this.setState({ ...this.state, widgetModel: model });

	onCompetitionSelect = (competition: CompetitionModel) => {
		this.updateWidgetModelState(
			IceHockeyStandingsModel.builder(this.state.widgetModel)
				.withTournament(competition)
				.withSeason({} as IceHockeySeasonModel)
				.withStage({} as IceHockeyStageModel)
				.build(),
		);
	};

	onSeasonSelect = (season: IceHockeySeasonModel) => {
		this.updateWidgetModelState(
			IceHockeyStandingsModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as IceHockeyStageModel)
				.build(),
		);
	};

	onStageSelect = (stage: IceHockeyStageModel) => {
		this.updateWidgetModelState(IceHockeyStandingsModel.builder(this.state.widgetModel).withStage(stage).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, IceHockeyStandingsModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	onHeaderDefaultOptionChange = (selectedHeaderDefaultOption: SelectMenuOptionType) => {
		onSelectionChangeFromMainComponent(
			selectedHeaderDefaultOption,
			IceHockeyStandingsModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	onDisplayHeaderChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(IceHockeyStandingsModel.builder(this.state.widgetModel).withDisplayHeader(e.target.checked).build());
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { contentLanguage } = this.props;

		return (
			<div data-qa='ice-hockey-standings-edit-component'>
				<Row className='mb-2'>
					<Col>
						<IceHockeyCompetitionSelectComponent
							onCompetitionSelect={this.onCompetitionSelect}
							selectedCompetition={
								widgetModel.competition && widgetModel.competition.id && widgetModel.competition.id.length > 0
									? widgetModel.competition
									: ({} as CompetitionModel)
							}
							isValid={isValid}
							language={contentLanguage}
							isRequired
							isClearable
							isBlocky
							sport={SportTypes.ICE_HOCKEY}
							id={`${SportTypes.ICE_HOCKEY}-${SPORT_SELECT_ATTRIBUTES.COMPETITION_SELECT}`}
						/>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<IceHockeySeasonSelectComponent
							onSeasonSelect={this.onSeasonSelect}
							selectedSeason={
								widgetModel.season && widgetModel.season.id && widgetModel.season.id.length > 0 ? widgetModel.season : ({} as IceHockeySeasonModel)
							}
							isValid={isValid}
							blockData={widgetModel}
							language={contentLanguage}
							isClearable
							sport={SportTypes.ICE_HOCKEY}
							id={`${SportTypes.ICE_HOCKEY}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
						/>
					</Col>
				</Row>
				<Row className='mb-4'>
					<Col>
						<IceHockeyStageSelectComponent
							onStageSelect={this.onStageSelect}
							selectedStage={
								widgetModel.stage && widgetModel.stage.id && widgetModel.stage.id.length > 0 ? widgetModel.stage : ({} as IceHockeyStageModel)
							}
							season={widgetModel.season}
							isValid={isValid}
							blockData={widgetModel}
							language={contentLanguage}
							isClearable
							sport={SportTypes.ICE_HOCKEY}
							id={`${SportTypes.ICE_HOCKEY}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
						/>
					</Col>
				</Row>
				{(!isValid && widgetModel.competition && widgetModel.competition.id === '') ||
					(widgetModel.competition === null && (
						<div className='mb-3'>
							<ErrorHandler errorMessage='no_competition_selected' />
						</div>
					))}
				<Row className='mt-2 mb-4'>
					<Col>
						<HeaderDisplayInput onDisplayHeaderChange={this.onDisplayHeaderChange} value={widgetModel.displayHeader} />
					</Col>
				</Row>
				{this.state.widgetModel.displayHeader && (
					<HeaderDefaultOptionWrapper
						options={BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTIONS}
						value={this.state.widgetModel.headerDefaultOption ? this.state.widgetModel.headerDefaultOption : ''}
						onOptionChange={this.onHeaderDefaultOptionChange}
					/>
				)}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
