import IceHockeyStageModel from '../../../../../../../models/v2/Stage/Entity/ice-hockey-stage.mode';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import { IceHockeySeasonModel } from '../../../../../../../models/v2/ice-hockey-season/entity/ice-hockey-season.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import IceHockeyStandingsBuilder from './ice-hockey-standings.builder';
import { IceHockeyStandingsJson } from './ice-hockey-standings.json';

export default class IceHockeyStandingsModel {
	readonly competition: CompetitionModel;
	readonly season: IceHockeySeasonModel;
	readonly stage: IceHockeyStageModel;
	readonly headerDefaultOption: string | null;
	readonly refreshTime: RefreshTimeValuesType;
	readonly displayHeader: boolean = false;

	private constructor(
		competition: CompetitionModel,
		season: IceHockeySeasonModel,
		stage: IceHockeyStageModel,
		headerDefaultOption: string | null,
		refreshTime: RefreshTimeValuesType,
		displayHeader: boolean,
	) {
		this.competition = competition;
		this.season = season;
		this.stage = stage;
		this.headerDefaultOption = headerDefaultOption;
		this.refreshTime = refreshTime;
		this.displayHeader = displayHeader;
	}

	toJson(): IceHockeyStandingsJson {
		return {
			competition: this.competition,
			season: this.season,
			stage: this.stage,
			headerDefaultOption: this.headerDefaultOption,
			refreshTime: this.refreshTime,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: IceHockeyStandingsJson): IceHockeyStandingsModel {
		return new IceHockeyStandingsModel(
			json.competition,
			json.season,
			json.stage,
			json.headerDefaultOption,
			json.refreshTime,
			json.displayHeader,
		);
	}

	static builder(standingsWidget?: IceHockeyStandingsModel): IceHockeyStandingsBuilder {
		return new IceHockeyStandingsBuilder(standingsWidget);
	}
}
