import React from 'react';
import { compose } from 'redux';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { ViewTypes } from '../../../../constants/general.constants';

import { BlockyV2Properties } from '../../../../v2/utils/helper';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { IceHockeyStandingsViewComponent } from './ice-hockey-standings-view.component';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import IceHockeyStandingsEditComponent from './ice-hockey-standings-edit.component';
import IceHockeyStandingsModel from '../models/ice-hockey-standings.model';
import { useTranslation } from 'react-i18next';

const IceHockeyStandingsWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, block, contentData, contentLanguage } = props;
	const [t] = useTranslation()

	if (view === ViewTypes.edit) {
		return <IceHockeyStandingsEditComponent block={block} contentData={contentData} t={t} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.ICE_HOCKEY} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<IceHockeyStandingsViewComponent
				preview={
					props.block.data.preview && props.block.data.preview.widgetModel
						? props.block.data.preview.widgetModel
						: IceHockeyStandingsModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(IceHockeyStandingsWidget);
