import moment from 'moment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import {
	extractFirstTeamsFromGameName,
	extractSecondTeamsFromGameName,
} from '../../../../../Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import { MARKET_TYPES } from '../../../../constants/odds.constants';
import { SportsOddsWidgetView } from '../../sports-odds/components/sport-odds-widget-view.component';
import SportOddsWidgetModel from '../../sports-odds/models/sport-odds-widget.model';
import GameWidgetModel from '../models/game-widget.model';
import { generateRefreshTimeOptionViewText } from '../../../../v2/partials/refresh-time-input/helper';
import { SportTypes } from '../../../../../../../constants/sport.types';

type Properties = {
	gamePreview: GameWidgetModel;
	t: any;
	oddsPreview: SportOddsWidgetModel;
};

export const BasketballSingleEventWidgetView: React.FunctionComponent<Properties> = ({ gamePreview, t, oddsPreview }) => {
	if (gamePreview && gamePreview.game && gamePreview.game.id) {
		const homeTeamName = extractFirstTeamsFromGameName(gamePreview.game.name, SportTypes.BASKETBALL);
		const awayTeamName = extractSecondTeamsFromGameName(gamePreview.game.name, SportTypes.BASKETBALL);
		const defaultMarket =
			!oddsPreview.market || oddsPreview.market === null || oddsPreview.market === '' ? MARKET_TYPES.TWO_WAY : oddsPreview.market;

		return (
			<div data-qa='basketball-single-event-view'>
				{gamePreview.displayOdds && <SportsOddsWidgetView blockPreview={oddsPreview} t={t} />}

				<Row className={`${!gamePreview.displayOdds ? 'p-4 text-center' : 'pb-4 pl-4 pr-4'}`}>
					<Col className='text-center'>
						{!gamePreview.displayOdds && (
							<>
								<Row className='mb-1'>
									<Col>{moment(gamePreview.game.startTime ? gamePreview.game.startTime : '').format('MMM Do YYYY, HH:mm')}</Col>
								</Row>
								<Row className='mb-1'>
									<Col className='text-right'>
										<strong>{homeTeamName}</strong>
									</Col>
									<span>{t('vs')}</span>
									<Col className='text-left'>
										<strong>{awayTeamName}</strong>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col className='text-left'>
								{t('display_odds')}:
								<strong>
									<span className={gamePreview.displayOdds ? 'text-success' : 'text-danger'}>
										{gamePreview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						<Row>
							{gamePreview.displayOdds && (
								<Col className='text-left'>
									<span>{t('default_market')}: </span>
									<strong>{t(defaultMarket)}</strong>
								</Col>
							)}
						</Row>
					</Col>
				</Row>

				{generateRefreshTimeOptionViewText(gamePreview.refreshTime)}
			</div>
		);
	}

	return null;
};
