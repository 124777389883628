import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import AssetsModel, { Logo } from '../sports-types/assets.model';
import TranslationsModel from '../sports-types/translations.model';

@jsonObject()
export default class Country {
	@jsonMember(AnyT)
	public id: any = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String, { name: 'url_flag' })
	public urlFlag: string = '';
	@jsonMember(String)
	public slug: string = '';
	@jsonMember(AssetsModel, { name: 'assets' })
	public assets: AssetsModel | null = null;
	@jsonArrayMember(TranslationsModel)
	public translations: TranslationsModel[] = [];
	@jsonMember(Logo)
	public display_asset = new Logo()
}
