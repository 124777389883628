import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';
import { stagesToOptions, stageToOption } from './helpers/ice-hockey-stage-select.helper';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import { useTranslation } from 'react-i18next';
import IceHockeyStageModel from '../../../../../models/v2/Stage/Entity/ice-hockey-stage.mode';
import IceHockeyStandingsModel from '../../v2/widgets-v2/ice-hockey-standings/models/ice-hockey-standings.model';
import { IceHockeySeasonModel } from '../../../../../models/v2/ice-hockey-season/entity/ice-hockey-season.model';

type Properties = {
	onStageSelect: (stage: IceHockeyStageModel) => void;
	blockData: IceHockeyStandingsModel;
	selectedStage: IceHockeyStageModel;
	season: IceHockeySeasonModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
};

const IceHockeyStageSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const { id, season, selectedStage, isClearable, isValid, isRequired, onStageSelect } = props;
	const [stagesOptions, setStagesOptions] = useState<IceHockeyStageModel[]>([]);
	const [t] = useTranslation();

	useEffect(() => {
		if (season && season.stages) {
			setStagesOptions(season.stages);
		}

		if (season && !season.id && stagesOptions.length > 0) {
			setStagesOptions([]);
		}
	}, [season]);

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('stage')}</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={stagesToOptions(stagesOptions)}
					value={selectedStage && selectedStage.id ? stageToOption(selectedStage) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={isClearable}
					onChange={(selection: SelectMenuOptionType) => {
						if (selection) {
							onStageSelect(selection.data);
						} else {
							onStageSelect({} as IceHockeyStageModel);
						}
					}}
				/>
				{!isValid && !selectedStage && <ErrorHandler errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default IceHockeyStageSelectComponent as React.ComponentType<Properties>;
