import { TypedJSON } from 'typedjson';
import { IceHockeySeasonModel } from '../../../../../../models/v2/ice-hockey-season/entity/ice-hockey-season.model';

export const responseToIceHockeySeasonModel = (response: IceHockeySeasonModel) => {
	const serializer = new TypedJSON(IceHockeySeasonModel);
	return serializer.parse(response) as IceHockeySeasonModel;
};

export const seasonsToOptions = (seasons: IceHockeySeasonModel[], competition?: string) => {
	if (seasons && seasons.length > 0) {
		return seasons.map((season) => {
			return seasonToOption(season, competition);
		});
	}

	return [];
};

export const seasonToOption = (season: IceHockeySeasonModel, competition?: string) => {
	const tournamentName = season && competition ? `${competition}-` : '';
	if (season && tournamentName) {
		return {
			value: season.id,
			label: tournamentName + season.name,
			data: season,
			type: 'season',
		};
	} else {
		return {};
	}
};
