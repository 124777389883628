import React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import SortDirection from '../../../../../../models/v2/sort-direction/sort-direction.model';
import { selectOptions, SORT_DIRECTION_OPTIONS } from '../helpers/sort-direction-select/sort-direction-select-component.helper';
import { useTranslation } from 'react-i18next';

type Properties = {
	onSortDirectionSelect: (direction: SortDirection) => void;
	selectedSortDirection: string;
	withLabel?: boolean;
	isClearable?: boolean;
	id: string;
	label: string;
};

const SortDirectionSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const { withLabel, onSortDirectionSelect, selectedSortDirection, isClearable, label, id } = props;
	const [t] = useTranslation();

	return (
		<Row data-qa={id}>
			<Col>
				{withLabel && <Label htmlFor={id}>{t(`${label}`)}:</Label>}
				<Select
					id={id}
					value={selectOptions(selectedSortDirection, t)}
					options={SORT_DIRECTION_OPTIONS(t)}
					placeholder={t('sort_direction_placeholder')}
					onChange={(sortDirection: SortDirection) =>
						sortDirection ? onSortDirectionSelect(sortDirection) : onSortDirectionSelect({} as SortDirection)
					}
					isClearable={isClearable}
				/>
			</Col>
		</Row>
	);
};

export default SortDirectionSelectComponent as React.ComponentType<Properties>;
