import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import LivescoreWidgetModel from '../../../../blocks/widgets/livescore/models/livescore-widget.model';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { BlockyV2Properties } from '../../../utils/helper';
import IceHockeyLivescoreWidgetEdit from './ice-hockey-livescore-block-edit.component';
import { IceHockeyLivescoreWidgetView } from './ice-hockey-livescore-view.component';
import { useTranslation } from 'react-i18next';

const IceHockeyLivescoreWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, block, contentData, contentLanguage } = props;
	const [t] = useTranslation();

	if (view === ViewTypes.edit) {
		return <IceHockeyLivescoreWidgetEdit block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.ICE_HOCKEY} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<IceHockeyLivescoreWidgetView
				livescorePreview={
					block.data.preview && block.data.preview.widgetModel ? block.data.preview.widgetModel : LivescoreWidgetModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(IceHockeyLivescoreWidget);
