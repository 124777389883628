import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import ErrorHandler from '../error-handler/error-handler';
import { useTranslation } from 'react-i18next';
import RoundModel from '../../../../../../models/v2/round/entity/round-model';
import { responseToRoundModel, roundsToOptions, roundToOption } from '../helpers/round-select/round-select-component.helper';
import BasketballStageModel from '../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballRoundModel from '../../../../../../models/v2/round/entity/basketball-round.model';

type Properties = {
	onRoundSelect: (round: BasketballRoundModel) => void;
	selectedRound: BasketballRoundModel;
	isValid: boolean;
	isRequired?: boolean;
	className?: string;
	id: string;
	selectedStage: BasketballStageModel;
};

const FootballSingleRoundSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [roundsOptions, setRoundsOptions] = useState<RoundModel[]>([]);
	const { isRequired, selectedRound, isValid, onRoundSelect, selectedStage } = props;

	useEffect(() => {
		if (selectedStage && selectedStage.rounds) {
			const rounds = selectedStage.rounds.map((round) => responseToRoundModel(round));
			const activeRound = selectedStage.rounds.find((round) => round.status === 'ACTIVE');
			activeRound && !selectedRound.id && onRoundSelect(activeRound);
			setRoundsOptions(rounds);
		}
	}, [selectedStage]);

	const roundsSelected = selectedRound === null || selectedRound === undefined;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('round')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					options={selectedStage && selectedStage.id ? roundsToOptions(roundsOptions) : []}
					value={selectedStage && selectedStage.id ? roundToOption(selectedRound) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					placeholder={t('select')}
					onChange={(selection: any) => {
						if (selection) {
							onRoundSelect(selection.data);
						} else {
							onRoundSelect({} as BasketballRoundModel);
						}
					}}
				/>

				{isRequired && <ErrorHandler t={t} arePropsValid={isValid} isValueSelected={roundsSelected} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default FootballSingleRoundSelectComponent as React.ComponentType<Properties>;
