import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import { customOption } from '../../../partials/shared/custom-select-option';
import ErrorHandler from '../error-handler/error-handler';
import { useTranslation } from 'react-i18next';
import BasketballStageModel from '../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import { stageToOption, stagesToOptions } from '../../../partials/stage-select/helpers/basketball-stage-select.helper';
import BasketballRoundModel from '../../../../../../models/v2/round/entity/basketball-round.model';

type Properties = {
	onStageSelect: (value: BasketballStageModel) => void;
	selectedStage: BasketballStageModel;
	seasonId: number | string;
	isValid: boolean;
	label?: string;
	isRequired?: boolean;
	preselectStage?: boolean;
	isClearable?: boolean;
	id: string;
};

const FootballSingleRoundStageSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const { id, onStageSelect, selectedStage, seasonId, label, isValid, isRequired, isClearable } = props;
	const [stagesOptions, setStagesOptions] = useState<BasketballStageModel[]>([]);

	useEffect(() => {
		if (seasonId) {
			getStages(seasonId);
		}
	}, [seasonId]);

	const getStages = (seasonId: number | string) => {
		if (seasonId) {
			HttpService.getSeasonStages(seasonId)
				.then((response: any) => {
					getNormalizedStages(response.data);
				})
				.catch((e: any) => e);
		}
	};

	const getNormalizedStages = (data: any) => {
		const stages = data.stages.map(({ stage, rounds }: { stage: BasketballStageModel; rounds: BasketballRoundModel[] }) => ({
			id: stage.id,
			name: stage.name,
			status: stage.status,
			startDate: stage.startDate,
			endDate: stage.endDate,
			type: stage.type,
			slug: stage.slug,
			rounds,
		})) as BasketballStageModel[];

		const activeStage = stages.find(({ status }) => status === 'ACTIVE');

		activeStage && !selectedStage.id && onStageSelect(activeStage);
		setStagesOptions(stages);
	};

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('stage')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					formatOptionLabel={customOption}
					options={stagesToOptions(stagesOptions)}
					value={selectedStage && selectedStage.id ? stageToOption(selectedStage) : []}
					onChange={(selection: any) => {
						if (selection) {
							onStageSelect(selection.data);
						} else {
							onStageSelect({} as BasketballStageModel);
						}
					}}
					placeholder={t('select')}
					isClearable={isClearable}
					styles={{
						menuPortal: (baseStyles) => ({
							...baseStyles,
							zIndex: 10000,
						}),
					}}
				/>
				{isRequired && (
					<ErrorHandler
						isValueSelected={!selectedStage || (selectedStage && !selectedStage.id)}
						arePropsValid={isValid}
						t={t}
						errorMessage='field_is_required'
					/>
				)}
			</Col>
		</Row>
	);
};

export default FootballSingleRoundStageSelectComponent as React.ComponentType<Properties>;
