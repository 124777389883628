import React from 'react';
import { Col, Row } from 'reactstrap';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import { generateDisplayHeaderOptionViewText } from '../../../partials/header-display-input/helper';
import RoundWidgetModel from '../models/football-round.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import { useTranslation } from 'react-i18next';

type Properties = {
	preview: RoundWidgetModel;
};

export const FootballSingleRoundView: React.FunctionComponent<Properties> = ({ preview }) => {
	const [t] = useTranslation();

	if (preview && preview.tournament && preview.tournament.id) {
		return (
			<>
				<Row className='px-4' data-qa='football-single-round-view'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								{preview.tournament && preview.tournament.name && (
									<div className='single-row'>
										<strong>{t('tournament')}:</strong> {preview.tournament.name}
									</div>
								)}
								<div className='single-row'>
									<strong>{t('season')}:</strong> {preview.season.name}
								</div>
								{preview.stage && preview.stage.id && (
									<div className='single-row'>
										<strong>{t('stage')}:</strong> {preview.stage.name}
									</div>
								)}
								{preview.round && preview.round.id && (
									<div className='single-row'>
										<strong>{t('round')}:</strong>

										<span className='pl-1'>{preview.round.name}</span>
									</div>
								)}
								{preview.limit && (
									<div className='single-row'>
										<strong>{t('limit')}:</strong> {preview.limit}
									</div>
								)}
								{preview.sortDirection && (
									<div className='single-row'>
										<strong>{t('sort_direction')}:</strong> {preview.sortDirection === 'asc' ? t('asc') : t('desc')}
									</div>
								)}
							</Col>
						</Row>
						<hr />
						<Row className='mb-2'>
							<Col className='text-center'>
								<Row>
									<Col className='text-left'>
										{t('display_odds')}:
										<strong>
											<span className={preview.displayOdds ? 'text-success' : 'text-danger'}>
												{preview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
											</span>
										</strong>
									</Col>
								</Row>
							</Col>
						</Row>
						{preview.displayOdds && preview.bookmakers && preview.bookmakers.length > 0 && (
							<Row className='mb-2'>
								<Col>
									{t('show')}:
									{preview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
										return (
											<img
												key={bookmaker.id}
												height='20'
												alt={bookmaker.name}
												className='h-20 mx-1'
												src={bookmaker.assets && bookmaker.assets[0] && bookmaker.assets[0].logo ? bookmaker.assets[0].logo : imagePlaceHolder}
												title={bookmaker.name}
											/>
										);
									})}
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				{generateDisplayHeaderOptionViewText(preview.displayHeader)}
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
