import React, { ChangeEvent } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import TournamentSelectComponent from '../../../partials/tournament-select/tournament-select.component';
import SeasonSelectComponent from '../../../partials/season-select/season-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import {
	checkIfOddsAutoCheckEnabled,
	createBlock,
} from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import LimitInput from '../../../../partials/limit-input/limit-input.component';
import HeaderDisplayInput from '../../../partials/header-display-input/header-display-input.component';
import { onSelectionChangeFromMainComponent } from '../../../partials/header-default-option-input/helper';
import { DisplayOddsCheckbox } from '../../odds/components/display-odds-checkbox.component';
import RoundWidgetModel from '../models/football-round.model';
import RoundDropdownSelect from '../../../partials/round-dropdown-select/round-dropdown-select.component';
import FootballSingleRoundStageSelectComponent from '../../../partials/stage-select/football-single-round-stage-select';
import FootballSingleRoundSelectComponent from '../../../partials/round-select/football-single-round-select.component';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import SortDirectionSelectComponent from '../../../partials/sort-direction-select/sort-direction-select.component';
import SortDirection from '../../../../../../../models/v2/sort-direction/sort-direction.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import SportBookmakerSelect from '../../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: RoundWidgetModel;
	isValid: boolean;
};

export default class FootballSingleRoundEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballSingleRound,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const roundModel = RoundWidgetModel.builder(this.state.widgetModel).build();

		if (isValid && data.widgetModel.stage.id && data.widgetModel.round.id) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: roundModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: RoundWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

		this.updateWidgetModelState(
			RoundWidgetModel.builder(this.state.widgetModel)
				.withTournament(tournament)
				.withSeason({} as SeasonModel)
				.withStage({} as BasketballStageModel)
				.withRound({} as BasketballRoundModel)
				.withDisplayOdds(isAutoCheckEnabled)
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.updateWidgetModelState(
			RoundWidgetModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as BasketballStageModel)
				.withRound({} as BasketballRoundModel)
				.build(),
		);
	};

	onStageSelect = (stage: BasketballStageModel) => {
		this.updateWidgetModelState(
			RoundWidgetModel.builder(this.state.widgetModel)
				.withStage(stage)
				.withRound({} as BasketballRoundModel)
				.build(),
		);
	};

	onRoundSelect = (round: BasketballRoundModel) => {
		this.updateWidgetModelState(RoundWidgetModel.builder(this.state.widgetModel).withRound(round).build());
	};

	onSortDirectionChange = (sortDirection: SortDirection) => {
		this.updateWidgetModelState(RoundWidgetModel.builder(this.state.widgetModel).withSortDirection(sortDirection.value).build());
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(RoundWidgetModel.builder(this.state.widgetModel).withLimit(limit).build());
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(RoundWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onRoundDropdownChange = (withRoundDropdown: boolean) => {
		this.updateWidgetModelState(RoundWidgetModel.builder(this.state.widgetModel).withRoundDropdown(withRoundDropdown).build());
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		this.updateWidgetModelState(RoundWidgetModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, RoundWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	onDisplayHeaderChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(RoundWidgetModel.builder(this.state.widgetModel).withDisplayHeader(e.target.checked).build());
	};

	onHeaderDefaultOptionChange = (selectedHeaderDefaultOption: SelectMenuOptionType) => {
		onSelectionChangeFromMainComponent(selectedHeaderDefaultOption, RoundWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

		return (
			<>
				<Row data-qa='football-single-round-edit'>
					<Col>
						<FormGroup>
							<TournamentSelectComponent
								isMulti={false}
								isBlocky
								isValid={isValid}
								selectedTournament={widgetModel.tournament}
								onTournamentSelect={this.onTournamentSelect}
								t={this.props.t}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOURNAMENT_SELECT}`}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<RoundDropdownSelect
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ROUND_DROPDOWN}`}
								isValid={isValid}
								tournament={widgetModel.tournament}
								roundDropdown={widgetModel.withRoundDropdown}
								onRoundDropdownChange={this.onRoundDropdownChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<SeasonSelectComponent
							withLabel
							isValid={isValid}
							isBlocky
							selectedSeason={widgetModel.season}
							tournamentId={widgetModel.tournament && widgetModel.tournament.id ? widgetModel.tournament.id : ''}
							onSeasonSelect={this.onSeasonSelect}
							t={this.props.t}
							id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
							preselectSeason
							preselectActiveSeason
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<FootballSingleRoundStageSelectComponent
								isValid={isValid}
								selectedStage={widgetModel.stage}
								seasonId={widgetModel.season && widgetModel.season.id && widgetModel.season.id}
								onStageSelect={this.onStageSelect}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<FootballSingleRoundSelectComponent
								isValid={isValid}
								selectedRound={widgetModel.round}
								selectedStage={widgetModel.stage}
								onRoundSelect={this.onRoundSelect}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ROUND_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<SortDirectionSelectComponent
							withLabel
							label={'sort_direction'}
							selectedSortDirection={widgetModel.sortDirection}
							onSortDirectionSelect={this.onSortDirectionChange}
							isClearable
							id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SORT_DIRECTION_SELECT}`}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<LimitInput onLimitChange={this.onLimitChange} value={widgetModel.limit} isValid={this.state.isValid} />
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<HeaderDisplayInput onDisplayHeaderChange={this.onDisplayHeaderChange} value={widgetModel.displayHeader} />
					</Col>
				</Row>
				<DisplayOddsCheckbox
					blockId={this.props.block.id}
					onDisplayOddsChange={this.onDisplayOddsChange}
					isChecked={widgetModel.displayOdds}
					sport={SportTypes.FOOTBALL}
				/>
				{widgetModel && widgetModel.displayOdds && bookmakerSelectionEnabled && (
					<Row className='mb-2'>
						<Col>
							<SportBookmakerSelect
								isMulti={false}
								isValid={this.state.isValid}
								showAllBookmakers={false}
								bookmakerSelectionEnabled={bookmakerSelectionEnabled}
								t={this.props.t}
								valueList={widgetModel.bookmakers ? widgetModel.bookmakers : []}
								onSelect={this.onBookmakerChange}
								contentLanguage={this.props.contentLanguage}
								sportType={SportTypes.FOOTBALL}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
							/>
						</Col>
					</Row>
				)}
				<Row className='mb-2'>
					<Col>
						<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
					</Col>
					<Col />
				</Row>
			</>
		);
	}
}
