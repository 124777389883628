import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { BlockyV2Properties } from '../../../utils/helper';
import RoundWidgetModel from '../models/football-round.model';
import { FootballSingleRoundView } from './football-single-round-view.component';
import FootballSingleRoundEditComponent from './football-single-round-edit.component';
import { useTranslation } from 'react-i18next';

const FootballSingleRoundWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, block, contentData, contentLanguage } = props;
	const [t] = useTranslation();

	if (view === ViewTypes.edit) {
		return <FootballSingleRoundEditComponent block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.FOOTBALL} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballSingleRoundView
				preview={
					block && block.data && block.data.preview && block.data.preview.widgetModel
						? block.data.preview.widgetModel
						: RoundWidgetModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballSingleRoundWidget);
