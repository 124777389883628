import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import LiveBlogModel from '../../../models/live-blog.model';
import { useTranslation } from 'react-i18next';
import './live-blog-seo.styles.scss';

type Properties = {
	liveBlog: LiveBlogModel;
	setLiveBlogSeoTitle: (value: string) => void;
	setLiveBlogSeoDescription: (value: string) => void;
	setLiveBlogSeoAutoSeoTitle: (value: boolean) => void;
};

export const LiveBlogSeoComponent: FunctionComponent<Properties> = ({
	liveBlog,
	setLiveBlogSeoTitle,
	setLiveBlogSeoDescription,
	setLiveBlogSeoAutoSeoTitle,
}) => {
	const [t] = useTranslation();
	const autoGenerateSeoTitle = liveBlog.seo && liveBlog.seo.automatic_seo_title ? liveBlog.seo.automatic_seo_title : false;
	const [copySeoTitle, setCopySeoTitle] = useState<boolean>(autoGenerateSeoTitle);

	const onSeoTitleChange = (input: ChangeEvent<HTMLInputElement>) => {
		setLiveBlogSeoTitle(input.target.value);
	};

	const onSeoDescriptionChange = (input: ChangeEvent<HTMLInputElement>) => {
		setLiveBlogSeoDescription(input.target.value);
	};

	const onCheckboxChange = () => {
		setCopySeoTitle(!copySeoTitle);
		setLiveBlogSeoAutoSeoTitle(!copySeoTitle);
	};

	useEffect(() => {
		if (copySeoTitle && liveBlog.title !== null) {
			setLiveBlogSeoTitle(liveBlog.title);
		}
	}, [copySeoTitle, liveBlog.title]);

	return (
		<Row className='mb-3' data={DATA_QA_ATTRIBUTES.LIVE_BLOG_SEO_COMPONENT}>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_SEO_TITLE}>
					<strong>{t('seo_title')}</strong>
				</Label>
				<Input
					id={'seo-title'}
					value={liveBlog.seo && liveBlog.seo.title ? liveBlog.seo.title : ''}
					className='form-control mb-2'
					type='text'
					placeholder={copySeoTitle ? '' : t('seo_title_placeholder')}
					disabled={copySeoTitle}
					onChange={onSeoTitleChange}
				/>
				<FormGroup check inline className='mb-3'>
					<Input
						className='form-check-input'
						type='checkbox'
						id='generate-seo-title-automatically'
						name='generate-seo-title-automatically'
						checked={copySeoTitle}
						onChange={onCheckboxChange}
					/>
					<Label className='form-check-label' check htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_SEO_GENERATE_TITLE}>
						{t('generate_auto_seo_title_from_title')}
					</Label>
				</FormGroup>
				<ErrorAsync t={t} errorType='seo_title_id' />
			</Col>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_SEO_DESCRIPTION}>
					<strong>{t('seo_description')}</strong>
				</Label>
				<Input
					id={'seo-description'}
					value={liveBlog.seo && liveBlog.seo.description ? liveBlog.seo.description : ''}
					className='mb-2 liveblog-seo-description-field'
					type='textarea'
					rows='1'
					placeholder={liveBlog.seo && liveBlog.seo.description && liveBlog.seo.description.length > 0 ? '' : t('seo_description_placeholder')}
					onChange={onSeoDescriptionChange}
				/>
				<ErrorAsync t={t} errorType='seo_description_id' />
			</Col>
		</Row>
	);
};
