import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';

@jsonObject
class Flag {
	@jsonMember(String)
	public url: string = '';
}
@jsonObject
export class Logo {
	@jsonMember(String)
	public url: string = '';
}

@jsonObject
export default class AssetsModel {
	@jsonMember(String)
	public url: string = '';
	@jsonMember(Logo, { name: 'logo' })
	public logo: Logo = new Logo();
	@jsonMember(Flag, { name: 'flag' })
	public flag: Flag = new Flag();
}
