import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import Country from '../../Country/Country.model';

@jsonObject()
export class DisplayAsset {
	@jsonMember(String)
	public url: string = '';
}

@jsonObject()
export default class CompetitionModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String, { name: 'name' })
	public name: string = '';
	@jsonMember(String)
	public region: string = '';
	@jsonMember(String)
	public gender: string = '';
	@jsonMember(Country)
	public country: Country = new Country();
	@jsonMember(DisplayAsset, { name: 'display_asset' })
	public displayAsset: DisplayAsset = new DisplayAsset();
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
	@jsonMember(String)
	public slug: string | null = null;
	@jsonMember(String)
	public sport?: string = '';
}
