import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class IceHockeyStageModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public short_name: string = '';
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
	@jsonMember(String)
	public slug: string | null = null;
}
