import * as React from 'react';
import Games from './games';
import {
	convertTeamIdsToGameSearchUrls,
	filterUpcomingGamesWithWrongDates,
	markGameModelAsSelected,
	responseToGameModel,
} from './helpers/games-container-helper';
import SportsConnectionsHttpService from '../../../Shared/advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../../../models/project/Project';
import { SPORT_SELECT_ATTRIBUTES } from '../../v2/widgets-v2/helpers/component-attributes.constants';
import { store } from '../../../../../store/store';
import { multiLingualService } from '../../../../../App';

type Properties = {
	entityIds?: any[];
	isMulti: boolean;
	teamNames: string[];
	maxNotStartedGames: number;
	maxFinishedGames: number;
	maxInterruptedGames: number;
	selectedGameIds?: any;
	selectedGameId?: any;
	onSelect: any;
	updateCheckAllGamesState?: any;
	upcomingGamesAreSelected?: any;
	finishedGamesAreSelected?: any;
	interruptedGamesAreSelected?: any;
	t: any;
	currentProject: Project;
	sport: string;
};

type State = {
	gamesFinished: any[];
	gamesNotStarted: any[];
	gamesInterrupted: any[];
	selectedTeamNames: any[];
	isContentLoading: boolean;
};

class GamesContainer extends React.Component<Properties, State> {
	private initialState = {
		gamesFinished: [],
		gamesNotStarted: [],
		gamesInterrupted: [],
		selectedTeamNames: [],
		isContentLoading: false,
	};

	constructor(props: any) {
		super(props);
		this.state = { ...this.initialState };
	}

	static getDerivedStateFromProps(props: any, state: any) {
		const { selectedGameId, selectedGameIds, isMulti } = props;

		let finished = markGameModelAsSelected(state.gamesFinished, selectedGameId, selectedGameIds, isMulti);
		let notStarted = markGameModelAsSelected(state.gamesNotStarted, selectedGameId, selectedGameIds, isMulti);
		let interrupted = markGameModelAsSelected(state.gamesInterrupted, selectedGameId, selectedGameIds, isMulti);
		state = {
			...state,
			gamesFinished: finished,
			gamesNotStarted: notStarted,
			gamesInterrupted: interrupted,
			selectedTeamNames: state.selectedTeamNames,
		};

		return state;
	}

	componentDidMount() {
		const { isMulti, entityIds, teamNames, selectedGameId, selectedGameIds, currentProject, sport } = this.props;

		if (entityIds && entityIds.length > 0) {
			this.setState({ ...this.state, selectedTeamNames: teamNames });
			this.getGames(convertTeamIdsToGameSearchUrls(entityIds, currentProject, sport), selectedGameId, selectedGameIds, isMulti);
		}
	}

	// Using componentDidUpdate to capture the prop changes received from the parent component
	// and update the current state of this component with the relevant data
	componentDidUpdate(prevProps: any) {
		let teamIds = this.props.entityIds;
		let prevTeamIds = prevProps.entityIds;
		let teamNames = this.props.teamNames;
		let selectedGameId = this.props.selectedGameId;
		let selectedGameIds = this.props.selectedGameIds;

		// If no teams are selected (additional check form prevTeamIds
		// is performed to escape endless component update)
		if (teamIds && prevTeamIds && prevTeamIds.length > 0 && teamIds.length < 1) {
			this.setState({ ...this.initialState });
		}

		// Updates the state with the selected team names
		// and when the request for games is received updates the state with
		// the needed games using the structure of the @GameModel
		if (teamIds && JSON.stringify(prevTeamIds) !== JSON.stringify(teamIds)) {
			this.setState({ ...this.state, selectedTeamNames: teamNames });

			if (teamIds.length < 3 && teamIds.length > 0) {
				this.getGames(
					convertTeamIdsToGameSearchUrls(teamIds, this.props.currentProject, this.props.sport),
					selectedGameId,
					selectedGameIds,
					this.props.isMulti,
				);
			}

			if (teamIds.length < 1) {
				this.setState({ ...this.initialState });
			}
		}
	}

	getGames(urls: string[], selectedGameId: string, selectedGameIds: string[], isMulti: boolean) {
		const { maxNotStartedGames, maxFinishedGames, maxInterruptedGames, currentProject } = this.props;
		this.setState({ ...this.state, isContentLoading: true });
		const language =
			multiLingualService.checkIfProjectIsMultiLingual(currentProject.languages) && multiLingualService.checkRoutePath()
				? multiLingualService.setCorrectAcceptLanguageCode(currentProject.languages.defaultLanguageCode.languageCode)
				: currentProject.language.split('-')[0];

		SportsConnectionsHttpService.requestMultipleSportsRequests([...urls], language)
			.then((response: any) => {
				let notStarted = response[0].data.results.slice(0, maxNotStartedGames).map((item: any) => responseToGameModel(item));
				let finished = response[1].data.results.slice(0, maxFinishedGames).map((item: any) => responseToGameModel(item));
				let interrupted = response[2].data.results.slice(0, maxInterruptedGames).map((item: any) => responseToGameModel(item));
				notStarted = markGameModelAsSelected(notStarted, selectedGameId, selectedGameIds, isMulti);
				finished = markGameModelAsSelected(finished, selectedGameId, selectedGameIds, isMulti);
				interrupted = markGameModelAsSelected(interrupted, selectedGameId, selectedGameIds, isMulti);
				this.setState({
					gamesFinished: finished,
					gamesNotStarted: notStarted,
					gamesInterrupted: interrupted,
					isContentLoading: false,
				});
			})
			.catch((e: any) => e);
	}

	render() {
		const {
			isMulti,
			onSelect,
			updateCheckAllGamesState,
			upcomingGamesAreSelected,
			finishedGamesAreSelected,
			interruptedGamesAreSelected,
			sport,
		} = this.props;

		return (
			<div data-qa={`${sport}-${SPORT_SELECT_ATTRIBUTES.EVENT_SELECT}`} className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
				<Games
					selectedTeamNames={this.state.selectedTeamNames}
					t={this.props.t}
					isMulti={isMulti}
					onGameSelect={onSelect}
					onGamesSelect={onSelect}
					gamesFinished={this.state.gamesFinished}
					gamesNotStarted={this.state.gamesNotStarted}
					gamesInterrupted={this.state.gamesInterrupted}
					updateCheckAllGamesState={updateCheckAllGamesState}
					upcomingGamesAreSelected={upcomingGamesAreSelected}
					finishedGamesAreSelected={finishedGamesAreSelected}
					interruptedGamesAreSelected={interruptedGamesAreSelected}
					sport={sport}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
	};
}

export default compose(connect(mapStateToProps))(GamesContainer);
