import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';
import { useTranslation } from 'react-i18next';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import { responseToIceHockeySeasonModel, seasonsToOptions, seasonToOption } from './helpers/ice-hockey-season-select.helper';
import IceHockeyStandingsModel from '../../v2/widgets-v2/ice-hockey-standings/models/ice-hockey-standings.model';
import { IceHockeySeasonModel } from '../../../../../models/v2/ice-hockey-season/entity/ice-hockey-season.model';
import StandingHttpService from '../../../../../services/rest/standings-http-service';

type Properties = {
	onSeasonSelect: (season: IceHockeySeasonModel) => void;
	blockData: IceHockeyStandingsModel;
	selectedSeason: IceHockeySeasonModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
};

const IceHockeySeasonSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const { id, blockData, sport, selectedSeason, onSeasonSelect, language, isClearable, isValid, isRequired } = props;
	const [seasonsOptions, setSeasonsOptions] = useState<IceHockeySeasonModel[]>([]);
	const [currentCompetition, setCurrentCompetition] = useState<string | undefined>();
	const [t] = useTranslation();

	useEffect(() => {
		if (blockData.competition && blockData.competition.id) {
			getSeasons(sport, blockData.competition.id, language);
		}
	}, []);

	useEffect(() => {
		if (blockData.competition && blockData.competition.id) {
			getSeasons(sport, blockData.competition.id, language);
		}

		if (!blockData.competition) {
			setSeasonsOptions([]);
		}
	}, [blockData.competition && blockData.competition.id]);

	const updateSeasonsOptionsState = (seasons: IceHockeySeasonModel[]) => setSeasonsOptions(seasons);

	const getSeasons = (sport: string, competitionId: string, lang: string) => {
		StandingHttpService.getAvailableSeasons(sport, competitionId, lang)
			.then((response: any) => {
				const competition = response.data.competition.name;
				const seasons = response.data.available_seasons.map((item: IceHockeySeasonModel) => responseToIceHockeySeasonModel(item));
				updateSeasonsOptionsState(seasons);
				setCurrentCompetition(competition);
			})
			.catch((e: any) => e);
	};

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('season')}</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={seasonsToOptions(seasonsOptions, currentCompetition)}
					value={selectedSeason && selectedSeason.id ? seasonToOption(selectedSeason, currentCompetition) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={isClearable}
					onChange={(selection: SelectMenuOptionType) => {
						if (selection) {
							onSeasonSelect(selection.data);
						} else {
							onSeasonSelect({} as IceHockeySeasonModel);
						}
					}}
				/>
				{!isValid && !selectedSeason && <ErrorHandler errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default IceHockeySeasonSelectComponent as React.ComponentType<Properties>;
